/*!

 File: helper-focus-first.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:

 Description:
    Helper function for focussing the first element in a form

*/

function focusFirst(el) {
     // Get list of focussable element in Popup
     if(el){
        
        var elFocusList = el.querySelectorAll(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        
        if(elFocusList && elFocusList.length>0){
            for (i = 0; i < elFocusList.length; ++i) { 
                if(!elFocusList[i].hasAttribute('disabled')){
                    elFocusList[i].focus();
                    break;
                } 
            }
        }

        /* 
        // FILTER METHOD (ES5)
        // Convert NodeList to an array
        var elFocusListArr = Array.prototype.slice.call(elFocusList);

        // Filter out disabled elements
        var elFilteredList = elFocusListArr.filter(function(elField) {
            return !elField.hasAttribute('disabled');
        });

        // Focus first element in new list
        if(elFilteredList && elFilteredList.length > 0){
            elFilteredList[0].focus();
        }
        */
       
     }

}
