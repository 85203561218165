/*
  Custom plugin for adding imagepicker images to Summernote
*/

(function (factory) {
    /* global define */
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
    // Browser globals
        factory(window.jQuery);
    }
}(function ($) {

    // Extends plugins for adding img picker button.
    //  - plugin is external module for customizing.
    $.extend($.summernote.plugins, {
    /**
    * @param {Object} context - context object has status of editor.
    */

    'imagepicker': function (context) {
        var self = this;

        // ui has renders to build ui elements.
        //  - you can create a button with `ui.button`
        var ui = $.summernote.ui;

        // add imagepicker button
        context.memo('button.imagepicker', function () {
            // create button
            var button = ui.button({
                contents: '<i class="fa fa-picture-o"/>',
                tooltip: 'Add image',
                click: function () {
                    // save range so we can insert at caret
                    context.invoke('editor.saveRange');
                    // Trigger modal open
                    self.$modalButton.trigger('click');
                }
            });

            // create jQuery object from button instance.
            var $hello = button.render();
            return $hello;
        });


        // This method will be called when editor is initialized by $('..').summernote();
        // You can create elements for plugin
        this.initialize = function () {

            // Get current editor as object
            var layoutInfo = context.layoutInfo;
            var $editor = layoutInfo.editor;

            // There should be an image-picker button as a sibling to the editor
            this.$modalButton = $editor.siblings('.js-thumb-picker-btn').first();
        };

        // This methods will be called when editor is destroyed by $('..').summernote('destroy');
        // You should remove elements on `initialize`.
        this.destroy = function () {
            this.$panel.remove();
            this.$panel = null;
        };
    }
    });
}));
