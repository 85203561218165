/*!

 File: helper-get-position.js
 Author: Mozilla
 Version: 1.0.1
 JS Dependencies:

 Description:
    Helper function for getting the position of an element on the page

*/

function getPosition(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { x: rect.left + scrollLeft, y: rect.top + scrollTop }
}
