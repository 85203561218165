/*
  Custom Ember plugin for adding classes to images in Summernote instead of style widths
*/

(function (factory) {
	if (typeof define === 'function' && define.amd) {
		define(['jquery'], factory);
	} else if (typeof module === 'object' && module.exports) {
		module.exports = factory(require('jquery'));
	} else {
		factory(window.jQuery);
	}
}(function ($) {
	var ui = $.summernote.ui;
	var dom = $.summernote.dom;
	var imgWidthPlugin = function (context) {
		var self = this;
		var options = context.options;
		var lang = options.langInfo;
		var editable = context.layoutInfo.editable;

		context.memo('button.imgWidthFull', function () {
			return ui.button({
				contents: '<span class="note-fontsize-10">100%</span>',
				tooltip: lang.imgWidth.widthFull,
				click: function(event) {
					var $img=$(editable.data('target'));
					$img.removeClass('content-img-quarter content-img-half content-img-full').addClass('content-img-full');
					context.invoke('editor.afterCommand');
				}
			}).render();
		});
		context.memo('button.imgWidthHalf', function () {
			return ui.button({
				contents: '<span class="note-fontsize-10">50%</span>',
				tooltip: lang.imgWidth.widthHalf,
				click: function(event) {
					var $img=$(editable.data('target'));
					$img.removeClass('content-img-quarter content-img-half content-img-full').addClass('content-img-half');
					context.invoke('editor.afterCommand');
				}
			}).render();
		});
		context.memo('button.imgWidthQuarter', function () {
			return ui.button({
				contents: '<span class="note-fontsize-10">25%</span>',
				tooltip: lang.imgWidth.widthQuarter,
				click: function(event) {
					var $img=$(editable.data('target'));
					$img.removeClass('content-img-quarter content-img-half content-img-full').addClass('content-img-quarter');
					context.invoke('editor.afterCommand');
				}
			}).render();
		});
	};

	$.extend(true, $.summernote, {
		plugins: {
			imgWidth: imgWidthPlugin
		},
		options: {
			popover: {
				imgWidth: [
					['imgWidth', ['imgWidthFull', 'imgWidthHalf', 'imgWidthQuarter']]
				]
			}
		},
		lang: {
			'en-US':{
				imgWidth:{
					widthFull:'Resize Full',
					widthHalf:'Resize Half',
					widthQuarter:'Resize Quarter',
				}
			}
		}
	});
}));
