/*!

 File: helper-trigger-event.js
 Author: Mozilla
 Version: 1.0.1
 JS Dependencies:

 Description:
    Cross browser event triggering

*/

function triggerEvent(el, etype){
    if (el.fireEvent) {
        (el.fireEvent('on' + etype));
    } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
    }
}
