/*
  Custom Ember plugin for adding links to images in Summernote
*/
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        module.exports = factory(require('jquery'));
    } else {
        factory(window.jQuery);
    }

    }(function ($) {

        var readFileAsDataURL = function (file) {
            return $.Deferred( function (deferred) {
                $.extend(new FileReader(),{
                    onload: function (e) {
                        var sDataURL = e.target.result;
                        deferred.resolve(sDataURL);
                    },
                    onerror: function () {
                        deferred.reject(this);
                    }
                }).readAsDataURL(file);
            }).promise();
        };

        $.extend(true,$.summernote.lang, {
            'en-US': { /* US English(Default Language) */
                imageLink: {
                    dialogTitle: 'Image Link',
                    tooltip: 'Image Link',
                    linkHref: 'URL',
                    linkTarget: 'Target',
                    linkTargetInfo: 'Options: _self, _blank, _top, _parent',
                    editBtn: 'OK'
                }
            }
        });

        $.extend($.summernote.options, {
            imageLink: {
                icon: '<i class="note-icon-link"/>',
                removeEmpty: true,
                imageFolder: ''
            }
        });

        $.extend($.summernote.plugins, {

            'imageLink': function (context) {
                var self    = this,
                    ui          = $.summernote.ui,
                    $note       = context.layoutInfo.note,
                    $editor     = context.layoutInfo.editor,
                    $editable   = context.layoutInfo.editable,
                    options     = context.options,
                    lang        = options.langInfo;

                context.memo('button.imageLink', function() {
                    var button = ui.button({
                    contents: options.imageLink.icon,
                    tooltip:  lang.imageLink.tooltip,
                        click: function () {
                        context.invoke('imageLink.show');
                    }
                });
                return button.render();
            });

            this.initialize = function () {
                var $container = options.dialogsInBody ? $(document.body) : $editor;
                var body = '';

                body += '   <div class="form-horizontal">' +
                '   <div class="form-group">' +
                       '        <label class="col-sm-2 control-label">' + lang.imageLink.linkHref + '</label>' +
                       '        <div class="col-sm-10">' +
                       '            <input class="form-control js-note-imageLink-link-href" type="text">' +
                       '        </div>' +
                       '    </div>' +
                       '    <div class="form-group">' +
                       '       <label class="col-sm-2 control-label">' + lang.imageLink.linkTarget + '</label>' +
                       '       <div class="col-sm-10">' +
                       '            <input class="form-control js-note-imageLink-link-target" type="text">' +
                       '            <span class="help-block m-b-none">' + lang.imageLink.linkTargetInfo + '</span>' +
                       '       </div>' +
                       '    </div>' +
                       '    </div>';

                this.$dialog=ui.dialog({
                    title:  lang.imageLink.dialogTitle,
                    body:   body,
                    footer: '<button href="#" class="btn btn-secondary js-note-imageLink-cancel-btn">Cancel</button><button href="#" class="btn btn-primary js-note-imageLink-btn">' + lang.imageLink.editBtn + '</button>'
                }).render().appendTo($container);
            };

            this.destroy = function () {
                ui.hideDialog(this.$dialog);
                this.$dialog.remove();
            };

            this.bindEnterKey = function ($input,$btn) {
                $input.on('keypress', function (e) {
                    if (e.keyCode === 13) $btn.trigger('click');
                });
            };

            this.bindLabels = function () {
                self.$dialog.find('.form-control:first').focus().select();
                self.$dialog.find('label').on('click', function () {
                    $(this).parent().find('.form-control:first').focus();
                });
            };

            this.show = function () {

                var $img    = $($editable.data('target'));
                var imgInfo = {
                    imgDom:  $img,
                    imgLink: $($img).parent().is("a") ? $($img).parent() : null
                };

                this.showimageLinkDialog(imgInfo).then( function (imgInfo) {
                    ui.hideDialog(self.$dialog);
                    var $img = imgInfo.imgDom;

                    if($img.parent().is("a")) $img.unwrap();

                    if (imgInfo.linkHref) {
                        var linkBody = '<a';
                        linkBody += ' href="' + imgInfo.linkHref + '" target="' + imgInfo.linkTarget + '"';
                        linkBody += 'class="summernote-img-link"';
                        linkBody += '></a>';
                        $img.wrap(linkBody);
                    }

                    $note.val(context.invoke('code'));
                    $note.change();
                });
            };

            this.showimageLinkDialog = function (imgInfo) {
                return $.Deferred( function (deferred) {

                    var $linkHref    = self.$dialog.find('.js-note-imageLink-link-href'),
                        $linkTarget  = self.$dialog.find('.js-note-imageLink-link-target'),
                        $editBtn     = self.$dialog.find('.js-note-imageLink-btn'),
                        $cancelBtn   = self.$dialog.find('.js-note-imageLink-cancel-btn');

                    $linkHref.val();
                    $linkTarget.val();

                    if (imgInfo.imgLink) {
                        $linkHref.val(imgInfo.imgLink.attr('href'));
                        $linkTarget.val(imgInfo.imgLink.attr('target'));
                    }

                    ui.onDialogShown(self.$dialog, function () {

                        context.triggerEvent('dialog.shown');

                        $editBtn.click( function (e) {
                            e.preventDefault();
                            deferred.resolve({
                                imgDom:     imgInfo.imgDom,
                                linkHref:   $linkHref.val(),
                                linkTarget: $linkTarget.val(),
                            });
                        });

                        $cancelBtn.click( function (e) {
                            e.preventDefault();
                            console.log('yo');
                            ui.hideDialog(self.$dialog);
                        });

                        self.bindEnterKey($editBtn);
                        self.bindLabels();

                    });

                    ui.onDialogHidden(self.$dialog, function () {
                        $editBtn.off('click');
                        $cancelBtn.off('click');
                        if (deferred.state() === 'pending') deferred.reject();
                    });

                    ui.showDialog(self.$dialog);
                });
            };
        }
    });

}));
