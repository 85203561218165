function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
    if (response.status === 'connected') {   // Logged into your webpage and Facebook.
        response.oauthProvider = 'facebook';
        $.post('/login', JSON.stringify(response), function () {
            window.location.href = '/profile';
        }).fail(function () {
            let $modal = $("#plain-form-modal");
            $modal.find('h4.modal-title').text('Register with this Facebook account?');
            $modal.find('.js-modal-save').text('Register');
            // show facebook name to confirm this is the correct account
            FB.api('/me', {
                locale: 'en_US',
                fields: 'id,first_name,last_name,email,link,gender,locale,picture'
            }, function (userData) {
                // Setup modal to confirm that Facebook registration is allowed
                // This contains a form that posts to /registration
                $form = $modal.find('form.modal-results-inner');
                $form.empty();
                $form
                    .attr('data-pre-submit-callback', '')
                    .attr('action', '/register')
                    .attr('method', 'post')
                    .attr('id', 'facebook-registration-form');
                let email = userData.email;
                // if email is not present then add an input field to get it
                let emailField = '';
                if (email) {
                    emailField = '<input type="hidden" id="facebook_email" name="email" value="' + userData.email + '" />';
                }

                let image_url = userData.picture.data.url;
                let imageField;
                if (image_url) {
                    imageField = '<input type="hidden" id="facebook_image_url" name="facebook_image_url" value="' + userData.picture.data.url + '" />';
                }

                // inject some hidden fields
                let facebookIdField = '<input type="hidden" id="facebook_id" name="facebook_id" value="' + userData.id + '" />';
                let userNameFields = '<input type="hidden" id="first_name" name="first_name" value="' + userData.first_name + '" /><input type="hidden" id="last_name" name="last_name" value="' + userData.last_name + '" />';

                let formContents = '<div class="grid-row"><div class="col-4"><img src="' + userData.picture.data.url + '" width="' + userData.picture.data.width + '" width="' + userData.picture.data.width + '" /></div><div class="col-8"><h3>' + userData.first_name + ' ' + userData.last_name;
                formContents += '</h3>';
                formContents += emailField + facebookIdField + userNameFields + imageField;
                formContents += '</div></div>';
                $form.append(formContents);
                // make the save button submit the form
                $modal.find('.modal-footer .btn-primary')
                    .text('Register')
                    .attr('type', 'submit')
                    .attr('form', 'facebook-registration-form');
                openMpForm($modal);
            });
        });
    } else {
        // Not logged into your webpage or we are unable to tell.
        console.log('FB user not logged in');
    }
}

function checkLoginState() {               // Called when a person is finished with the Login Button.
    FB.getLoginStatus(function (response) {   // See the onlogin handler
        statusChangeCallback(response);
    });
}

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


function checkLoginState() {
    FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
    });
}