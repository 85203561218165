/*!

 File: helper-ScrollIt.js
 Author: Mozilla
 Version: 1.0.5 [MINA-983] - Check for existance
 JS Dependencies:

 Description:
    Helper function for animated scroll
    Based on http://github.com/cferdinandi/smooth-scroll

*/

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([], (function () {
			return factory(root);
		}));
	} else if (typeof exports === 'object') {
		module.exports = factory(root);
	} else {
		root.ScrollIt = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, (function (window) {

	'use strict';

    var ScrollIt = function(options) {
        // Returning a new init object
        return new ScrollIt.lib.init(options);
    }

    /**
	 * Check to see if user prefers reduced motion
	 * @param  {Object} settings Script settings
	 */
	var reduceMotion = function () {
		if ('matchMedia' in window && window.matchMedia('(prefers-reduced-motion)').matches) {
			return true;
		}
		return false;
	};

    /**
	 * Get the height of an element.
	 * @param  {Node} elem The element to get the height of
	 * @return {Number}    The element's height in pixels
	 */
	var getHeight = function (elem) {
		return parseInt(window.getComputedStyle(elem).height, 10);
	};

    // Default easing pattern (easeInOutCubic)
    var easingPattern = function (time) {
		var pattern;

		// Default Easing Patterns (easeInOutCubic)
		pattern = time < 0.5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1; // acceleration

		return pattern || time; // no easing, no acceleration
	};

    /**
	 * Determine the document's height
	 * @returns {Number}
	 */
	var getDocumentHeight = function () {
		return Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);
	};

    /**
	 * Calculate how far to scroll
	 * Clip support added by robjtede - https://github.com/cferdinandi/smooth-scroll/issues/405
	 * @param {Element} anchor       The anchor element to scroll to
	 * @param {Number}  headerHeight Height of a fixed header, if any
	 * @param {Number}  offset       Number of pixels by which to offset scroll
	 * @param {Boolean} clip         If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
	 * @returns {Number}
	 */
	var getEndLocation = function (anchor, offset, clip) {
        var location = 0;

        var isNum = Object.prototype.toString.call(anchor) === '[object Number]' ? true : false;

        // Could have passed in an element instead of a number
        if(isNum) {
            location = anchor;
        }else{

    		if (anchor && anchor.offsetParent) {
    			do {
    				location += anchor.offsetTop;
    				anchor = anchor.offsetParent;
    			} while (anchor);
    		}
    		location = Math.max(location - offset, 0);
    		if (clip) {
    			location = Math.min(location, getDocumentHeight() - window.innerHeight);
    		}
        }

 		return location;
	};

    var animationInterval;

    var me;

    // Defining the prototype of the object
    ScrollIt.lib = ScrollIt.prototype = {

        constructor: ScrollIt,

        init: function(options) {
            // Verifying and validating the input object
            if (!options) {
                options = {};
            }

            // Creating the options object
            this.options = {};

            // Validating the options
            this.options.pos = options.pos || null;
            this.options.duration = options.duration || 500; // Speed of scroll
            this.options.offset = parseInt(options.offset) || 0; // Offset for floating header
            this.options.callback = options.callback || false; // Callback function

            // Returning the current object for chaining functions
            return this;

        },
        // Do the scroll
        runScroll: function() {
            me = this;
            // Cancel any in progress scrolls
			me.cancelScroll();

            var startLocation = window.pageYOffset; // Current location on the page

            var elem = document.querySelector('#workbookpage-5');

            var endLocation = getEndLocation(this.options.pos, this.options.offset); // Location to scroll to

            var distance = endLocation - startLocation; // distance to travel

            var documentHeight = getDocumentHeight();
			var timeLapsed = 0;

            var speed = this.options.duration;

            var callback = this.options.callback;

            var start, percentage, position;

            /**
			 * Stop the scroll animation when it reaches its target (or the bottom/top of page)
			 * @param {Number} position Current position on the page
			 * @param {Number} endLocation Scroll to location
			 * @param {Number} animationInterval How much to scroll on this loop
			 */
			var stopAnimateScroll = function (position, endLocation) {

				// Get the current location
				var currentLocation = window.pageYOffset;

				// Check if the end location has been reached yet (or we've hit the end of the document)
				if (position == endLocation || currentLocation == endLocation || ((startLocation < endLocation && window.innerHeight + currentLocation) >= documentHeight)) {

					// Clear the animation timer
					me.cancelScroll();

					// Reset start
					start = null;

					return true;

				}
			};

            /**
			 * Loop scrolling animation
			 */
			var loopAnimateScroll = function (timestamp) {
				if (!start) { start = timestamp; }
				timeLapsed += timestamp - start;
				percentage = speed === 0 ? 0 : (timeLapsed / speed);
				percentage = (percentage > 1) ? 1 : percentage;
				position = startLocation + (distance * easingPattern(percentage));
				window.scrollTo(0, Math.floor(position));
				if (!stopAnimateScroll(position, endLocation)) {
					animationInterval = window.requestAnimationFrame(loopAnimateScroll);
					start = timestamp;
				}else{
                    // Run Callback function
                    if (typeof callback === 'function'){
                        callback();
                    }
                }

			};

            /**
			 * Reset position to fix weird iOS bug
			 * @link https://github.com/cferdinandi/smooth-scroll/issues/45
			 */
			if (window.pageYOffset === 0) {
				window.scrollTo(0, 0);
			}

            // If the user prefers reduced motion, jump to location
			if (reduceMotion()) {
				window.scrollTo(0, Math.floor(endLocation));
				return;
			}

            // Start scrolling animation
			me.cancelScroll();

            // Just jump if animation isn't supported
            if (!window.requestAnimationFrame) {
                window.scroll(0, endLocation);
                // Run Callback function
                if (typeof callback === 'function'){
                    callback();
                }
                return;
            }

    		window.requestAnimationFrame(loopAnimateScroll);

        },

        // Cancel scroll
        cancelScroll: function() {
            cancelAnimationFrame(animationInterval);
            animationInterval = null;
        },

    }

    // Setting up the prototype for the init object
    ScrollIt.lib.init.prototype = ScrollIt.lib;


    // Returning the Notify function to be assigned to the window object/module
    return ScrollIt;


}));
