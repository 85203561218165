/*!

    File: helper-get-position.js
    Author: Mozilla
    Version: 1.0.2
    JS Dependencies:

    Description:
        Nodelist loop helper
        https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/

    Usage:
        var els = elTarget.querySelectorAll('.js-el');

        forEachNode(els, function (i, el) {
            el.classList.add('active');
        });

*/

var forEachNode = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]); // passes back stuff we need
    }
};
