/*!

 File: helper-serialize-array.js
 Author: Ember
 Version: 1.0.4 [CCS-72]
 JS Dependencies:

 Description:
    https://vanillajstoolkit.com/helpers/serializearray/
    Serialize all form data into an array of objects.

*/

var isObject = function(obj) {
    return obj === Object(obj);
};


var serializeArray = function (form, thisDisabled) {

	// Setup our serialized data
	var serialized = [];
	//var serialized = {};

	// Option to allow disabled fields
	var allowDisabled = false;
	if(thisDisabled) {
		allowDisabled = true;
	}

	// Loop through each field in the form
	for (var i = 0; i < form.elements.length; i++) {

		var field = form.elements[i];

		// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
		if (!field.name || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

		// Might want to allow disabled
		if(!allowDisabled && field.disabled) continue;

		// If a multi-select, get all selections
		if (field.type === 'select-multiple') {
			// Multi selects have blank arrays at end of name
			// We want to remove that in AJAX posts
			var thisName = field.name;
			thisName = thisName.replace(/\[]\s*$/, '');
			// post options array
			var thisOptions = [];
			//
			for (var n = 0; n < field.options.length; n++) {
				if (!field.options[n].selected) continue;
				// eg {fieldName : fieldValue}
				//var obj = {};
				//obj[field.name] = field.options[n].value;
				//serialized[field.name] = field.options[n].value;
				thisOptions.push(field.options[n].value);
				// eg {"name":"fieldName","value":"fieldValue"}
				//serialized.push({
					//name: field.name,
					//value: field.options[n].value
				//});
			}
			serialized.push({
				name: field.name,
				value: thisOptions
			});
		}

		// Convert field data to a query string
		else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
			// eg {fieldName : fieldValue}
			//var obj = {};
			//obj[field.name] = field.value;
			//serialized[field.name] = field.value;
			// eg {"name":"fieldName","value":"fieldValue"}
			serialized.push({
				name: field.name,
				value: field.value
			});
		}
	}

	// Now loop through the resulting array and break into objects
	// eg this:
	/*
		[
			{
				"name":"contact[name]",
				"value":"Ruari"
			},
			{
				"name":"contact[email]",
				"value":"ruari@ember.co.uk"
			},
			{
				"name":"id",
				"value":"199"
			},
			{
				"name":"option[]",
				"value":"fish"
			},
			{
				"name":"option[]",
				"value":"cheese"
			},
            {
				"name":"job[0][company_name]",
				"value":"Virgin Media"
			},
            {
				"name":"job[1][company_name]",
				"value":"GiffGaff"
			},
            {
				"name":"job[0][colour]",
				"value":"Red"
			},
            {
				"name":"job[1][colour]",
				"value":"Green"
			}
		]
	*/
	// becomes
	/*
		{
			contact: {
				name: Ruari,
				email: ruari@ember.co.uk,
			},
			id: 199,
			option: {
				0: fish,
				1: cheese
			},
            job: {
                0: {
                    company_name: Virgin Media,
                    colour: Red
                },
                1: {
                    company_name: GiffGaff,
                    colour: Green
                }
            }
		}
	*/

	// Basic version of https://github.com/marioizquierdo/jquery.serializeJSON

	var serializedObject = {};


    serialized.forEach(function(obj, i) {

        // convert contact[name] to ['contact','name]']
        // or job[0][company_name] to ['job','0]', company_name]]
        var keys = obj.name.split('['); // split string into array

        // Remove any trailing ]
        keys = keys.map(function(key){
            return key.replace(/\]/g, '');
        });

        // ensure no opening bracket ("[foo][inn]" should be same as "foo[inn]")
        if (keys[0] === '') { keys.shift(); }

        if (keys.length === 1) {
            // just a 1 dimensional name - eg name="id" value="199"
            serializedObject[obj.name] = obj.value;
        }else{

            // If object doesn't already exist create it
            if(!isObject( serializedObject[keys[0]] )){
                serializedObject[keys[0]] = {};
            }

            if (keys.length === 3) {
                // Create 2D objects
                if(!isObject( serializedObject[keys[0]][keys[1]] )){
                    serializedObject[keys[0]][keys[1]] = {};
                }
            }

            // Reference to this object
            var currObj = serializedObject[keys[0]];

            if(!keys[2]){
                // a 1D array option[] or contact[email]
                if(keys[1] === '') {
                    // eg name="option[]" value="fish"
                    var objLength = Object.keys(currObj).length.toString();
                    //
                    currObj[objLength] = obj.value;
                }else{
                    // eg name="contact[email]" value="ruari@ember.co.uk"
                    currObj[keys[1]] = obj.value;
                }

            }else{
                // a 2D array eg job[0][company_name]
                var currChildObj = currObj[keys[1]];
                //
                currChildObj[keys[2]] = obj.value;
            }

        }

    });

	return serializedObject;

};
